import { getEnum } from 'lib/utils';
import { Feature } from 'src/.generated/Feature.EnterpriseWeb.model';
import Headline from 'src/helpers/Headline/Headline';
import ImageWrapper from 'src/helpers/Media/ImageWrapper';
import { BodyCopy } from 'src/helpers/BodyCopy';
import classNames from 'classnames';
import { useTheme } from 'lib/context/ThemeContext';
import { SwatchCollectionTheme } from './SwatchCollection.theme';
import RichTextWrapper from 'src/helpers/RichTextWrapper/RichTextWrapper';
import { Foundation } from 'src/.generated/Foundation.EnterpriseWeb.model';
import { EnumField } from 'lib/utils/get-enum';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import { useState } from 'react';
import { Field, ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { Button } from '../Button';
import Disclaimer from 'src/helpers/DisclaimerText/DisclaimerText';
import { useCurrentScreenType, getBreakpoint } from 'lib/utils/get-screen-type';
import { GenericFieldValue } from '@sitecore-jss/sitecore-jss/types/layout/models';
import SvgIcon from '../SvgIcon/SvgIcon';

export type SwatchStyles = 'small-circles' | 'large-circles' | 'square';
export type LayoutStyles = 'full-width' | 'side-by-side';
export type SwatchModal = 'true' | 'false';

export type SwatchCollectionProps =
  Foundation.EnterpriseWeb.Enterprise.FieldSets.Products.SwatchCollections & {
    fields?: {
      swatchCollection?: Feature.EnterpriseWeb.Enterprise.Elements.Swatches.SwatchCollection & {
        fields?: {
          swatches: Feature.EnterpriseWeb.Enterprise.Elements.Swatches.Swatch[];
        };
      };
      swatchStyle?: EnumField<SwatchStyles>;
      swatchModal?: EnumField<SwatchModal>;
    };
    layoutStyle: LayoutStyles;
  };
const SwatchCollection = (props: SwatchCollectionProps) => {
  const swatchModal = getEnum(props.fields?.swatchModal) ?? 'false';
  const swatchStyle = getEnum(props.fields?.swatchStyle) ?? 'large-circles';
  const [activeSwatch, setActiveSwatch] =
    useState<Feature.EnterpriseWeb.Enterprise.Elements.Swatches.Swatch | null>(null);
  const { themeData, themeName } = useTheme(SwatchCollectionTheme(props.layoutStyle));

  let swatchImgClasses = '';
  let swatchesGap = '';
  let swatchWidth = '';
  const { currentScreenWidth } = useCurrentScreenType();
  const modalStyle = currentScreenWidth > getBreakpoint('md') ? 'swatch-modal' : '';
  const modalSize = currentScreenWidth > getBreakpoint('md') ? 'fluid' : 'large';

  switch (swatchStyle) {
    case 'small-circles':
      swatchImgClasses =
        'h-[64px] ml:h-[86px] w-[64px] ml:w-[86px] rounded-full [&_img]:rounded-full';
      swatchWidth = 'w-[82px] ml:w-[86px] ';
      swatchesGap = classNames(
        'gap-y-m gap-x-[25px] ml:gap-x-[62px] ml:justify-start ml:pl-xxs justify-evenly',
        themeName === 'rba' ? 'ml:pl-m mt-m ml:mt-l mb-s ml:gap-y-l' : 'ml:gap-y-m ml:pl-0'
      );
      break;
    case 'large-circles':
      swatchImgClasses = 'h-xxl w-xxl ml:h-[160px] ml:w-[160px] rounded-full [&_img]:rounded-full';
      swatchWidth = 'w-xxl ml:w-[160px] ml:pl-0';
      swatchesGap = classNames(
        'gap-x-[52px] gap-y-m ml:gap-x-[38px] ml:justify-start justify-evenly ml:pl-0',
        themeName === 'rba' ? 'my-s' : ''
      );
      break;
    case 'square':
      swatchImgClasses = 'h-[156px] w-[156px] ml:h-[160px] ml:w-[160px]';
      swatchWidth = 'w-[156px] ml:w-[160px] ';
      swatchesGap = classNames(
        'gap-x-[15px] gap-y-m ml:justify-start justify-evenly',
        themeName === 'rba' ? 'mt-m ml:gap-x-l' : 'ml:gap-x-[43px]'
      );
      break;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleModal = (swatch: any) => {
    setActiveSwatch(swatch);
  };

  return (
    <div>
      <Headline
        useTag="h3"
        classes={themeData.classes.swatchTitle}
        fields={{
          headlineText: props.fields?.swatchCollection?.fields.swatchCollectionName ?? {
            value: '',
          },
        }}
      />
      <BodyCopy
        classes={themeData.classes.swatchDescription}
        fields={{
          body: props.fields?.swatchCollection?.fields.swatchCollectionDescription ?? { value: '' },
        }}
      />
      <div className={classNames('flex flex-wrap [&>*:last-child]:justify-start', swatchesGap)}>
        {props.fields?.swatchCollection?.fields.swatches.map((swatch, index) => (
          <div key={index} className={classNames(swatchWidth, 'flex flex-col')}>
            <div
              onClick={() => handleModal(swatch)}
              className={classNames(swatchImgClasses, 'mx-auto mb-xxs', {
                'cursor-pointer':
                  swatchModal &&
                  (swatch.fields?.largerImage as ImageField)?.value?.src &&
                  (swatch.fields?.productOrHardwareImage as ImageField)?.value?.src,
              })}
            >
              <ImageWrapper
                imageLayout="responsive"
                image={swatch.fields.swatchImage as ImageField}
              />
            </div>
            <Headline
              useTag="h4"
              classes={themeData.classes.swatchLabel}
              fields={{
                headlineText: (swatch.fields.swatchName as Field<string>) ?? { value: '' },
              }}
            />
          </div>
        ))}
      </div>
      <RichTextWrapper
        classes={themeData.classes.swatchFooterCopy}
        field={props.fields?.swatchCollection?.fields.swatchCollectionFooterCopy ?? { value: '' }}
      />
      {swatchModal &&
        (activeSwatch?.fields?.largerImage as ImageField)?.value?.src &&
        (activeSwatch?.fields?.productOrHardwareImage as ImageField)?.value?.src && (
          <ModalWrapper
            size={modalSize}
            handleClose={() => setActiveSwatch(null)}
            isModalOpen={!!activeSwatch}
            customOverlayclass=""
            customContentWrapperclass={modalStyle}
            showCloseButton={false}
          >
            <div className="grid grid-cols-3">
              {/* First Image: Full Bleed */}
              <div className="col-span-3">
                <ImageWrapper
                  image={activeSwatch?.fields?.largerImage as ImageField | undefined}
                  additionalDesktopClasses="content-center aspect-[16/10] !h-[145px] pb-[16px] w-full"
                  additionalMobileClasses="!aspect-[15/6] col-span-3 pb-[16px] !h-[145px]"
                  imageLayout={currentScreenWidth > getBreakpoint('md') ? 'fill' : 'fill'}
                />
                <button
                  onClick={() => setActiveSwatch(null)}
                  className="absolute top-0 right-0 p-4"
                >
                  <SvgIcon icon="close" size="md" />
                </button>
              </div>

              {/* Second Image, Button, and Rich Text Wrapper */}
              <div className="col-span-3 flex px-5 pt-4 md:flex-col">
                {/* Second Image */}
                <ImageWrapper
                  image={activeSwatch?.fields.productOrHardwareImage as ImageField | undefined}
                  additionalDesktopClasses="h-[90px] w-[155px] object-contain content-center justify-self-center self-center"
                  additionalMobileClasses="w-[60%]"
                  ratio={'square'}
                />

                {/* Button and Rich Text */}
                <div className="flex flex-col items-center pl-4 md:pl-0">
                  <RichTextWrapper
                    field={activeSwatch?.fields.productHardwareCaption as Field<string>}
                    classes="items-top font-sans [&_a:hover]:underline text-theme-text text-sm-xs ml:text-xs font-medium mt-m ml:mt-l mb-xs inline  max-w-[300px] break-words text-center"
                  />
                  {/* @ts-ignore cutom button */}
                  <Button
                    field={activeSwatch?.fields.link as LinkField}
                    icon={{
                      id: 'arrow-icon-id',
                      url: '#',
                      name: 'Arrow',
                      displayName: 'Arrow',
                      fields: {
                        Value: {
                          value: 'arrow',
                        },
                      },
                    }}
                    classes="mb-[16px]"
                  />
                </div>
              </div>

              {/* Disclaimer */}
              <div className="col-span-3 justify-items-center ">
                <Disclaimer
                  disclaimerClasses="opacity-50 pb-[20px] pr-[20px] pl-[20px] text-center max-w-[200px] break-words"
                  fields={{
                    value: (activeSwatch?.fields?.disclaimer as Field<GenericFieldValue>)?.value,
                  }}
                />
              </div>
            </div>
          </ModalWrapper>
        )}
    </div>
  );
};

export default SwatchCollection;
